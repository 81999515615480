@import './styles/_variables.scss';
@import './styles/_pop.scss';
@import './styles/_helpers.scss';
@import './styles/_buttons.scss';
@import './styles/_forms.scss';
@import './styles/_header.scss';
@import './styles/_footer.scss';
@import './styles/_home.scss';
@import './styles/_dogs.scss';
@import './styles/_adoptions.scss';
@import './styles/_expect.scss';
@import './styles/_about.scss';
@import './styles/_contact.scss';
@import './styles/_quotes.scss';

html, body, #root {
    font-family: $app-font;
    min-height: 100vh;
}

.app {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
}

.content {
    max-width: $max-width;
    padding: $space;
    margin: 0 auto;
}

h1, h2 {
    color: lighten($mute-grey, 20%);
    font-family: $heading-font;
    letter-spacing: 2px;
    margin-top: 0;

    &.section-header {
        margin-top: 1em;
    }
}

@media screen and (max-width: 745px) {
    h1 {
        font-size: 1.5em;
    }
}

a {
    color: darken($brand-color, 35%);
    cursor: pointer;
    text-decoration: none;

    .fab {
        color: darken($brand-color, 20%);
        display: block;
        transition: color 500ms ease;
    }

    &:hover {
        .fab {
            color: $secondary-color;
            transition: color 500ms ease;
        }
    }
}

p {
    color: $text-color;
    line-height: 1.5em;
    letter-spacing: .25px;
    margin-top: 0;

    a {
        text-decoration: underline;
    }
}

.top-button {
    align-items: center;
    background: $brand-color;
    border-radius: 50px;
    border: none;
    box-shadow: none;
    bottom: 1em;
    cursor: pointer;
    color: $white;
    display: flex;
    font-size: 1em;
    height: 50px;
    justify-content: center;
    line-height: 1em;
    opacity: .8;
    position: fixed;
    right: 1em;
    width: 50px;
    z-index: 5;

    &:focus {
        outline: none;
    }
}

.divider {
    border-bottom: 1px dashed $brand-color;
    height: 1px;
    margin: 3em 0;
    position: relative;

    .moon {
        background: $white;
        height: 20px;
        left: 48%;
        padding: 10px;
        position: absolute;
        top: -18px;
    }
}
