$about-breakpoint: 745px;

.about-container {
    width: 100%;

    .about {
        display: flex;
        flex-direction: row;
        margin-bottom: 1.5em;

        @media screen and (max-width: $about-breakpoint) {
            flex-direction: column;

            .main {
                margin-bottom: $lg-space;
                width: 100%;
            }

            .side {
                padding-left: 0;
                width: 100%;
            }
        }
    }

    .main {
        width: 70%;
    }

    .side {
        padding-left: $lg-space;
        width: 30%;

        ul {
            padding-left: 0;
            list-style: none;

            li {
                line-height: 1.5em;
                margin-bottom: $sm-space;

                &::after {
                    content: '\f08e';
                    display: inline-block;
                    font-family: $icon-font;
                    font-size: .75em;
                    color: $dark-brand-color;
                    margin-left: $sm-space;
                }
            }
        }

        a {
            font-size: .85em;
        }
    }

    p {
        display: inline-block;
    }

    .content-image {
        border-radius: 4px;
        box-shadow: $box-shadow;
        display: inline-block;
        max-width: 350px;

        @media screen and (max-width: $about-breakpoint) {
            max-width: 200px;
        }

        &.right {
            float: right;
            margin: $sm-space 0 $sm-space $space;
        }

        &.left {
            float: left;
            margin: $sm-space $space $sm-space 0;
        }

        &.full-width {
            margin-bottom: 1em;
            max-width: 100%;
            width: 100%;
        }
    }
}
