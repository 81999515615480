$header-breakpoint: 800px;
$header-md-breakpoint: 700px;
$header-sm-breakpoint: 600px;

// contact details header section

.contact-container {
    background: $mute-grey;
    width: 100%;

    .contact {
        color: darken( $off-white, 15% );
        font-size: .9em;

        @media screen and (max-width: $header-sm-breakpoint) {
            flex-direction: column;

            .contacts {
                margin-left: 0;

                .icon-ref {
                    &::before {
                        display: none;
                        visibility: hidden;
                    }
                }
            }

            .social {
                margin-top: $space;
            }
        }

        @media screen and (max-width: $header-md-breakpoint) {
            flex-wrap: wrap;

            .names {
                flex-grow: 1;
                order: 1;
                width: 100%;
                text-align: center;
                justify-content: center;
                margin-bottom: $space;
            }

            .contacts {
                flex-grow: 2;
                order: 2;
            }

            .social {
                flex-grow: 2;
                order: 3;
            }
        }
    }

    span {
        margin-right: $lg-space;
        white-space: nowrap;

        &:last-child {
            margin-right: 0;
        }
    }

    .name {
        font-weight: 700;
    }

    .contacts {
        margin-left: auto;
    }

    .social {
        text-decoration: none;
        margin-left: $space;
        justify-content: flex-end;

        a {
            color: $brand-color;
        }

        a:last-child {
            margin-left: $space;
        }

        i {
            font-size: 1.5em;
        }
    }
}

// navigation header section

.header-container {
    background: $dark-grey;
    width: 100%;

    @media screen and ( max-width: $header-sm-breakpoint ) {
        .content {
            padding: $space;
        }

        .logo {
            display: none;
            visibility: hidden;
        }

        .navigation {
            margin-left: auto;
        }
    }

    @media screen and ( min-width: 601px ) and ( max-width: $header-breakpoint ) {

        .content {
            padding: .5em 1em;
        }

        .logo {
            display: flex;
            margin-top: 0 !important;
            min-width: 40% !important;

            img {
                width: 90%;
                position: relative !important;
                top: auto !important;
            }
        }
    }

    @media screen and (max-width: $header-sm-breakpoint) {
        .navigation {
            margin: 0 auto;
            padding-left: 4em;
        }
    }

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .logo {
        margin-top: -20px;
        height: 90px;
        min-width: 260px;

        img {
            height: 100px;
            position: absolute;
            top: 47px;
        }
    }
}

.header-border {
    display: table;
    height: 8px;
    width: 100%;
    background: linear-gradient(90deg, rgba($secondary-color, 1) 0%, rgba($brand-color, 1) 90%);
    margin-bottom: $space;
}

.navigation {
    align-items: center;
    display: flex;
    justify-content: space-between;

    a {
        border-bottom: 3px solid transparent;
        color: $off-white;
        font-size: .9em;
        font-weight: 700;
        letter-spacing: 1px;
        margin-left: $lg-space;
        line-height: 2em;
        padding-top: 1em;

        &:hover {
            &::after {
                content: '';
                height: 2px;
                width: 100
            }
        }

        &.active {
            border-bottom: none;

            &::after {
                content: '';
                display: block;
                height: 3px;
                width: 100%;
                background: $brand-gradient;
            }
        }
    }
}
