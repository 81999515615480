$contact-breakpoint1: 745px;

.contact-pg-container {
    width: 100%;

    .contact {
        display: flex;
        flex-direction: row;
        margin-bottom: $lg-space;

        @media screen and (max-width: $contact-breakpoint1) {
            flex-direction: column;

            .main {
                margin-bottom: $lg-space;
                width: 100%;
            }

            .side {
                padding-left: 0;
                width: 100%;
            }
        }
    }

    .main {
        width: 60%;

        iframe {
            @media screen and (min-width: 850px) {
                width: 500px;
            }

            @media screen and (min-width: 451px) and (max-width: 849px) {
                width: 400px;
            }

            @media screen and (min-width: 321px) and (max-width: 450px) {
                width: 350px;
            }

            @media screen and (max-width: 320px) { //iphone 5
                width: 290px;
            }
        }
    }

    .side {
        padding-left: $lg-space;
        width: 40%;

        ul {
            color: $text-color;
            list-style: none;
            padding-left: 0;

            li {
                margin-bottom: $space;

                b {
                    margin-right: $sm-space;
                }
            }
        }

        iframe {
            @media screen and (max-width: 320px) {
                //iphone 5
                width: 290px !important;
            }
        }
    }
}
