$primary-color: $dark-brand-color;

input,
textarea {
    background: linear-gradient(to bottom, $white 96%, $primary-color 4%);
    background-position: -350px 0;
    background-repeat: no-repeat;
    background-size: 350px 100%;
    border: 0;
    border-bottom: solid 1px $primary-color;
    color: $text-color;
    display: block;
    font-family: $app-font;
    margin: $lg-space 0;
    padding: 12px 0;
    transition: all .3s cubic-bezier(.64, .09, .08, 1);
    width: 350px;
}

input:focus,
input:valid,
textarea:focus,
textarea:valid {
    background-position: 0 0;
    box-shadow: none;
    outline: none;
}

input::-webkit-input-placeholder,
textarea::-webkit-textarea-placeholder {
    font-family: $app-font;
    transition: all .3s ease-in-out;
}

input:focus::-webkit-input-placeholder,
input:valid::-webkit-input-placeholder {
    color: $primary-color;
    font-size: 11px;
    transform: translateY(-16px);
    visibility: visible !important;
    padding-bottom: 10px;
}
