$dogs-breakpoint1: 745px;
$dogs-breakpoint2: 450px;

.dogs-container {
    width: 100%;

    .content-image.left {
        float: left;
        margin: 0 1.5em 1.5em 0;
        max-width: 300px;
    }

    .dogs {
        display: flex;
        flex-direction: row;
        margin-bottom: $lg-space;

        .side {
            color: $mute-grey;
            margin-left: $space;
            width: 25%;

            @media screen and (max-width: $dogs-breakpoint1) {
                margin-left: 0;
                width: 0;
            }
        }

        .main {
            display: flex;
            flex-direction: column;
            padding-left: $lg-space;
            width: 75%;

            @media screen and (max-width: $dogs-breakpoint1) {
                width: 100%;
                padding-left: 3.5em;
            }
        }
    }

    .fa-venus, .fa-mars {
        margin-right: .25em;
    }

    .fa-venus {
        color: $dark-secondary-color;
    }

    .fa-mars {
        color: darken($brand-color, 20%);
    }

    .sidebar-nav {
        list-style-type: none;
        margin: 0;
        padding: 0;
        position: sticky;
        top: 1em;
        overflow-y: scroll;
        max-height: 80vh;
        height: fit-content;

        &::-webkit-scrollbar {
            width: 10px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: $brand-color;
            border-radius: 10px;
        }

        .retired {
            color: lighten($mute-grey, 20%);
            letter-spacing: .5px;
            font-size: .85em;
            font-weight: 700;
            padding-left: 1em;
            margin-bottom: 0;
        }

        a {
            color: $mute-grey;
            cursor: pointer;
            display: block;
            font-weight: 700;
            line-height: 1.75em;
            margin-top: .5em;
            padding-left: .5em;

            @media screen and (max-width: $dogs-breakpoint1) {
                &:first-child {
                    margin-top: 0;
                }
            }

            &:hover {
                background: rgba($brand-color, .2);
            }

            &.scroll-active {
                background: rgba($brand-color, .4);
            }
        }

        .sidebar-section ul {
            padding-left: .5em;

            @media screen and (max-width: $dogs-breakpoint1) {
                padding-left: 0;
            }
        }

        ul {
            list-style-type: none;
            margin: .5em 0;
            padding: 0;

            li {

                a {
                    color: lighten($mute-grey, 20%);
                    cursor: pointer;
                    font-weight: 700;
                    line-height: 1.75em;
                    padding-left: .5em;
                    margin-top: 0;

                    &:hover {
                        background: rgba($brand-color, .2);
                    }

                    &.female {
                        &:hover {
                            background: rgba($secondary-color, .2);
                        }
                    }
                }

            }
        }
    }

    .stud {
        color: $brand-accessible;
        font-size: .65em;
        margin-left: 2em;
    }

    .split {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        @media screen and (max-width: $dogs-breakpoint1) {
            flex-direction: column;
        }

        .area {
            flex: 1;
            padding-right: $lg-space;
            width: 50%;

            @media screen and (max-width: $dogs-breakpoint1) {
                padding: 0;
                width: 100%;
            }

            &:last-child {
                padding-right: 0;
            }

            .headline {
                @media screen and (max-width: $dogs-breakpoint1) {
                    background: none;
                    padding: 0;
                }

                &:before {
                    font-family: $icon-font;
                    display: inline-block;
                    padding-right: $sm-space;
                }
            }

            .yes {
                &:before {
                    color: $yes-color;
                    content: '\f00c';
                }
            }

            .no {
                &:before {
                    color: $no-color;
                    content: '\f00d';
                }
            }

            ul {
                list-style: none;
                padding: 0;

                &.price-table {
                    list-style-type: disc;
                    margin: $sm-space 0;
                    padding-left: 2em;
                }

                li {
                    line-height: 2em;
                }
            }
        }
    }
}

.dog-page {
    display: flex;
    flex-direction: column;
    padding-left: 1.5em;
    width: 75%;

    @media screen and (max-width: $dogs-breakpoint2) {
        padding-left: 0;
        padding-top: 3em;
    }

    @media screen and (min-width: 451px) and (max-width: $dogs-breakpoint1) {
        padding-left: 3.5em;
    }

    @media screen and (max-width: $dogs-breakpoint1) {
        width: 100%;
    }
}

table {
    &.cost {
        thead {

            td {
                background: $brand-color;
                padding: .5em 1em;

                &:first-child {
                    width: 80px;
                }
            }
        }
    }

    &.dog-chart {

        .title {
            font-weight: 800;
            color: lighten($mute-grey, 30%);
            font-size: .75em;
            margin-bottom: $sm-space;
            display: block;
            text-transform: uppercase;
        }

        tr {

            td {
                line-height: 1.25em;
                padding-bottom: .75em;
                text-transform: uppercase;
                vertical-align: top;
            }
        }
    }
}
