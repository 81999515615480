.centered {
    margin-top: 0;
    text-align: center;
}

.simple-table {
    line-height: 1.5em;
    margin-bottom: $lg-space;
    text-align: left;
    width: 400px;

    @media screen and (max-width: 745px) {
        width: 100%;
    }

    td {
        padding: .25em .5em;
    }
}

.inline-flex {
    align-items: center;
    display: flex;
    flex-direction: row;
}
