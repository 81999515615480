$adoption-breakpoint: 800px;

.adoptions-container {
    width: 100%;

    .adoptions {
        display: flex;
        flex-direction: row;
        margin-bottom: $lg-space;

        @media screen and (max-width: $adoption-breakpoint) {
            flex-direction: column;
        }
    }

    .side {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 30%;

        @media screen and (max-width: $adoption-breakpoint) {
            width: 100%;
            margin-top: 2em;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            order: 2;
        }

        .call-to-action {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: $lg-space;
        }

        i {
            margin-right: 5px;
        }
    }

    .main {
        width: 70%;

        @media screen and (max-width: $adoption-breakpoint) {
            width: 100%;
        }
    }
}
