.expect-container {
    width: 100%;
}

.expect {
    display: flex;
    flex-direction: column;
    margin-bottom: $lg-space;
}

.image-caption {
    display: flex;
    flex-direction: column;
    margin-bottom: $lg-space;

    img {
        height: auto;
        margin-bottom: $sm-space;
        width: 100%;
    }

    small {
        color: lighten($mute-grey, 20%);
        font-weight: 700;
        letter-spacing: 1px;
        text-align: right;
    }
}

.content-images {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 1em 0;
    overflow: hidden;

    img {
        width: 350px;
    }

    @media screen and (max-width: 750px) {
        align-items: center;
        flex-direction: column;
        justify-content: center;

        img {
            width: 100%;
        }
    }
}

.galleries {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 1em;

    .gallery-preview {
        display: flex;
        flex-direction: column;
        margin: $sm-space;
        max-width: 200px;
        max-height: 200px;

        .litter-gallery {
            display: block;

            > div > div {
                height: 135px !important;

                img {
                    margin: 0 !important;
                    position: relative !important;
                    height: 135px !important;
                    width: 200px !important;
                    
                }
            }
        }

        img {
            height: 135px;
            width: 200px;
        }

        .preview-title {
            background: $white;
            border: 1px solid $mid-grey;
            color: $dark-grey;
            display: flex;
            flex-direction: column;
            text-align: center;
            padding: .5em 0;

            .title {
                font-size: .85em;
                font-weight: 500;
                margin-bottom: .25em;
            }

            .view {
                color: $dark-brand-color;
                font-size: .75em;
                text-decoration: underline;
                letter-spacing: 1px;
                opacity: .8;
            }
        }
    }
}
