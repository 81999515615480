$footer-breakpoint: 850px;
$footer-sm-breakpoint: 500px;

.footer-container {
    background: $mute-grey;
    margin-top: auto;
    width: 100%;

    p {
        color: $mid-grey;
    }

    .footer {
        color: $mid-grey;
        font-size: .9em;
    }

    .left {
        display: flex;
        flex-direction: column;
        margin-top: .5em;
        width: 18%;

        @media screen and (max-width: $footer-breakpoint) {
            flex-grow: 2;
            order: 2;
        }

        @media screen and (max-width: $footer-sm-breakpoint) {
            flex-grow: 1;
            order: 2;
            width: 100%;
            text-align: center;
            margin-bottom: $lg-space;
        }

        .tagline {
            color: $light-grey;
            font-weight: 700;
            margin-bottom: $space;
        }

        .copyright {
            font-size: .9em;
        }
    }

    .middle {

        @media screen and (max-width: $footer-breakpoint) {
            flex-grow: 1;
            margin-bottom: 2em;
            order: 1;
            width: 100%;
        }

        .footer-nav {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            font-weight: 700;
            margin: 0 auto;

            @media screen and (max-width: $footer-breakpoint) {
                flex-wrap: wrap;
                justify-content: space-around;
                width: 100%;
            }

            a, .nav-group {
                margin: $sm-space;
            }
        }

        .nav-group {

            ul {
                list-style-type: none;
                padding: 0;
                font-weight: 300;
                color: $light-grey;
                line-height: 1.75em;

                @media screen and (max-width: $footer-breakpoint) {
                    display: none;
                    visibility: hidden;
                }

                a {
                    color: $light-grey;
                }
            }
        }

        a {
            color: $mid-grey;
        }
    }

    .right {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        width: 20%;
        flex-direction: column;

        @media screen and (max-width: $footer-breakpoint) {
            flex-grow: 2;
            order: 3;
        }

        @media screen and (max-width: $footer-sm-breakpoint) {
            flex-grow: 1;
            order: 3;
            width: 100%;
            margin-bottom: $lg-space;
            align-items: center;

            .social,
            .contact,
            .certificates {
                text-align: center;
            }
        }

        .social {
            margin-bottom: $sm-space;
            display: flex;
            flex-direction: row;
            text-decoration: none;

            a {
                font-size: 2em;
                margin-left: $sm-space;

                @media screen and (max-width: $footer-sm-breakpoint) {
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    .contact {
        text-align: right;
        line-height: 1.5em;
    }

    .footer-main {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        padding: $lg-space 0;

        @media screen and (max-width: $footer-breakpoint) {
            flex-wrap: wrap;
            max-width: 100%;
        }
    }

    .certificates {
        display: flex;
        flex-direction: column;
        text-align: right;
    }
}

.icon-spacer img {
    max-width: 50px;
    opacity: .25;

    @media screen and (max-width: $footer-breakpoint) {
        display: none;
        visibility: hidden;
    }
}
