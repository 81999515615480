.carousel-wrapper {
    background-color: $brand-color;
    color: $text-color;
    height: 75px;
    padding: 1em 0;
    width: 100%;
    text-align: center;
    font-style: italic;

        @media screen and (max-width: 800px) {
            display: none;
            visibility: hidden;
        }
}

.carousel {
    height: 100px;
    overflow: hidden;
    width: 800px;
    margin: 0 auto;

    li {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        line-height: 1.5em;
    }
}
