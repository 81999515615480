.homepage-container {
    width: 100%;

    .homepage {
        display: flex;
        flex-direction: row;
        margin-bottom: $lg-space;

        @media screen and (max-width: $home-breakpoint) {
            flex-direction: column;
        }

        .main {
            display: flex;
            flex-direction: column;
            padding-right: $lg-space;
            width: 65%;

            @media screen and (max-width: $home-breakpoint) {
                width: 100%;
                padding-right: 0;
            }

            img {
                border-radius: 4px;
            }

            .feature-image {
                margin-bottom: 1em;
                width: 100%;
            }

            .styled-table {
                .male {
                    background: rgba($brand-color, .6);

                    td {
                        background: transparent;
                    }
                }

                .female {
                    background: rgba($secondary-color, .6);

                    td {
                        background: transparent;
                    }
                }
            }
        }

        .side {
            color: $mute-grey;
            display: flex;
            flex-direction: column;
            margin-left: $space;
            width: 35%;

            @media screen and (max-width: $home-breakpoint) {
                flex-direction: row;
                flex-flow: wrap;
                margin-left: 0;
                width: 100%;
            }

            .section {
                margin-bottom: $lg-space;

                @media screen and (max-width: $home-breakpoint) {
                    border: 2px solid $brand-color;
                    border-radius: 5px;
                    padding: $space;
                    margin: $space;
                    width: 41%;

                    img {
                        display: none;
                        visibility: hidden;
                    }
                }

                @media screen and (max-width: $home-md-breakpoint) {
                    border-bottom: none;
                    border-right: none;
                    border-left: none;
                    border-top: 1px dashed $brand-color;
                    margin: $space 0;
                    width: 100%;

                    p:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            h2 {
                font-family: $heading-font;
                font-size: 1.25em;
                margin-bottom: .25em;
            }

            .thumb {
                max-width: 100%;
            }

            .social {
                a {
                    align-items: center;
                    display: inline-flex;
                    margin-left: .25em;
                    text-decoration: none;
                    vertical-align: sub;

                    i {
                        font-size: 1.5em;
                        line-height: 1.25em;
                        margin-right: .25em;
                    }
                }
            }
        }
    }
}

.homepage-footer {
    display: flex;
    flex-direction: column;
}

.dog-features {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: $space;

    @media screen and (max-width: $home-breakpoint) {
        flex-wrap: wrap;
    }

    .soon {
        align-items: center;
        background-color: $brand-color;
        border-radius: 100px;
        display: flex;
        height: 150px;
        justify-content: center;
        text-align: center;
        width: 150px;

        @media screen and (max-width: $home-sm-breakpoint) {
            height: 80%;
            width: 80%;
        }
    }

    .dog {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin: $space;
        width: 20%;

        @media screen and (max-width: $home-breakpoint) {
            margin: 0 0 2em;
            width: 33.3%;
        }

        .female {
            color: $dark-secondary-color;
        }

        span {
            font-family: $heading-font;
            color: darken($brand-color, 35%);
            font-weight: 700;
            letter-spacing: 1px;

            &.soon {
                color: $white;
                font-weight: 500;
            }
        }
    }

    img {
        border-radius: 100px;
        margin-bottom: $space;

        @media screen and (max-width: $home-sm-breakpoint) {
            width: 80%;
        }
    }
}
