/* stylelint-disable */
$app-font: 'Lato',
    'Helvetica Neue',
    sans-serif;
$heading-font: 'Vollkorn', 
    serif;
$code-font: 'Courier New',
    monospace;
$icon-font: 'Font Awesome\ 5 Pro';

$white: #fff;
$off-white: #f9f9f9;
$light-grey: #ededed;
$mid-grey: #999;
$mute-grey: #383838;
$dark-grey: #272525;
$black: #000;

$brand-color: #99ced4;
$dark-brand-color: darken($brand-color, 30%);
$secondary-color: #fca5f1;
$dark-secondary-color: darken($secondary-color, 30%);
$brand-accessible: #9e026e;

$brand-gradient: linear-gradient(45deg, rgba($secondary-color, 1) 0%, rgba($brand-color, 1) 90%);
$brand-dark-gradient: linear-gradient(45deg, rgba($secondary-color, 1) 0%, rgba($brand-color, 1) 80%);

$box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.2),
0px 1px 1px 0px rgba(0, 0, 0, 0.1),
0px 2px 1px -1px rgba(0, 0, 0, 0.1);

$box-shadow-active: 0px 3px 9px 0px rgba(0, 0, 0, 0.2),
0px 3px 3px 0px rgba(0, 0, 0, 0.14),
0px 6px 3px -1px rgba(0, 0, 0, 0.12);

$text-color: $mute-grey;
$link-color: $brand-color;

$yes-color: #418441;
$no-color: #d04647;

$space: 1em;
$sm-space : .5em;
$lg-space: 1.5em;

$max-width: 1180px;
$mobile-size: 400px;
$phablet-size: 675px;
$breakpoints: ('small': 320px,
    'medium': 768px,
    'large': 1024px,
);
/* stylelint-enable */

$home-breakpoint: 900px;
$home-md-breakpoint: 805px;
$home-sm-breakpoint: 500px;
