$width: 300px;
$sm-width: 220px;
$height: 70px;
$border: 3px;
$degree: 45deg;
$size: 150%;

@mixin clip-frame($width, $height, $border) {
    -webkit-clip-path: polygon(0% 100%, $border 100%, $border $border, $width - $border $border, $width - $border $height - $border, $border $height - $border, $border 100%, 100% 100%, 100% 0%, 0% 0%);
}

.shadow {
    box-shadow: $box-shadow;
}

// stylized contact icons

.icon-ref {
    display: flex;
    flex-direction: row;
    align-items: center;

    &::before {
        align-items: center;
        background: $brand-gradient;
        border-radius: 30px;
        color: $mute-grey;
        display: flex;
        font-family: $icon-font;
        font-size: .85em;
        height: 15px;
        justify-content: center;
        margin-right: $sm-space;
        padding: $sm-space;
        width: 15px;
    }

    &.phone {

        &::before {
            content: '\f095';
        }
    }

    &.email {

        &::before {
            content: '\f0e0';
        }
    }
}

.headline {
    font-size: 1.25em;
    color: lighten($mute-grey, 20%);
    font-weight: 700;
    line-height: 1.75em;

    @media screen and (max-width: 745px) {
        font-size: 1em;
        background: #efefef;
        padding: .5em 1em;
        line-height: 1.5em;
    }
}

blockquote {
    font-size: 1.4em;
    width: 80%;
    margin: $space auto;
    font-family: Open Sans;
    font-style: italic;
    color: $mute-grey;
    padding: 1.2em 30px 1.2em 75px;
    border-left: 8px solid $brand-color;
    line-height: 1.6;
    position: relative;
    background: $light-grey;

    @media screen and (max-width: 745px) {
        font-size: 1em;
        font-weight: 500;
        margin: $space 0;
        padding: 1em 1em 1em 2em;
        width: 75%;

        &::before {
            left: 2px !important;
        }
    }
}

blockquote::before {
    font-family: Arial;
    content: '\201C';
    color: $brand-color;
    font-size: 4em;
    position: absolute;
    left: 10px;
    top: -10px;
}

blockquote::after {
    font-family: Arial;
    content: '\201D';
    color: $brand-color;
    font-size: 4em;
    position: absolute;
    right: 30px;
    bottom: -50px;
}

blockquote span {
    display: block;
    color: $dark-grey;
    font-style: normal;
    font-size: .75em;
    font-weight: bold;
    margin-top: 1em;
}

.no-select {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    user-select: none;
}

table {
    border-spacing: $sm-space;
    padding: 0;

    tbody {
        td {
            background: $light-grey;
            padding: .5em 1em;
            font-size: .85em;
        }
    }
}

@keyframes flipdown {

    0% {
        opacity: 0;
        transform-origin: top center;
        transform: rotateX(-90deg);
    }

    5% {
        opacity: 1;
    }

    80% {
        transform: rotateX(8deg);
    }

    83% {
        transform: rotateX(6deg);
    }

    92% {
        transform: rotateX(-3deg);
    }

    100% {
        transform-origin: top center;
        transform: rotateX(0deg);
    }
}

.accordion {
    background: $off-white;
    list-style: none;
    perspective: 900;
    padding: 0;
    margin: 0;
    margin-bottom: $lg-space;

    input {
        margin: 0;
    }

    p {

        &:nth-child(2) {
            margin-bottom: 0;
        }
    }

    li {
        position: relative;
        padding: 0;
        margin: 0;
        padding-top: $space;
        border-top: 1px dashed $brand-color;
        animation: flipdown 0.5s ease both;

        &:nth-of-type(1) {
            animation-delay: .5s;
            border-top: none;
        }

        &:nth-of-type(2) {
            animation-delay: .75s;
        }

        &:nth-of-type(3) {
            animation-delay: 1s;
        }

        &:nth-of-type(4) {
            animation-delay: 1.25s;
        }

        &:nth-of-type(5) {
            animation-delay: 1.5s;
        }

        &:last-child {
            padding-bottom: .25em;
        }

        h3 {
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            -webkit-touch-callout: none;
            user-select: none;
            color: $dark-brand-color;
            margin: 0;
            padding-left: $space;
        }

        p {
            padding: 0 $space;
            position: relative;
            overflow: hidden;
            max-height: 800px;
            transition: all 0.25s ease-in-out;
            opacity: 1;
            transform: translate(0, 0);
            margin-top: 14px;
            z-index: 2;
        }

        i {
            position: absolute;
            transform: translate(-6px, 0);
            margin-top: .65em;
            right: $space;

            &::before, &::after {
                content: '';
                transition: all .25s ease-in-out;
                position: absolute;
                background-color: $dark-brand-color;
                width: 3px;
                height: 9px;
            }

            &::before {
                transform: translate(-2px, 0) rotate(45deg);
            }

            &::after {
                transform: translate(2px, 0) rotate(-45deg);
            }
        }

        input[ type = 'checkbox' ] {
            position: absolute;
            cursor: pointer;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0;

            &:checked {
                &~p {
                    margin-top: 0;
                    max-height: 0;
                    opacity: 0;
                    transform: translate(0, 50%);
                }

                &~i {
                    &::before {
                        transform: translate(2px, 0) rotate(45deg);
                    }

                    &::after {
                        transform: translate(-2px, 0) rotate(-45deg);
                    }
                }
            }
        }
    }
}

#mobile-nav {
    @media screen and (min-width: 851px) {

        input,
        span,
        p {
            display: none;
            visibility: hidden;
        }

        .header-nav {
            display: flex;
            flex-direction: row;
            margin-left: auto;
        }
    }

    @media screen and (max-width: 850px) {
        background: transparent;
        display: block;
        position: relative;
        top: .25em;
        right: 0%;
        z-index: 1;
        -webkit-user-select: none;
        user-select: none;

        p {
            position: absolute;
            right: 45px;
            color: $white;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-size: .85em;
            line-height: 1.75em;
        }

        input {
            display: block;
            width: 100px;
            height: 32px;
            position: absolute;
            top: -7px;
            right: 0;
            margin: 0;
            cursor: pointer;
            opacity: 0;
            z-index: 2;
            -webkit-touch-callout: none;

            &:checked~div {
                opacity: 1;
                visibility: visible;
            }

            &:checked~span {
                opacity: 1;
                transform: rotate(45deg) translate(-2px, -1px);
                background: $mid-grey;
            }

            &:checked~span:nth-last-child(3) {
                opacity: 0;
                transform: rotate(0deg) scale(0.2, 0.2);
            }

            &:checked~span:nth-last-child(2) {
                transform: rotate(-45deg) translate(0, -1px);
            }
        }

        span {
            display: block;
            width: 33px;
            height: 4px;
            margin-bottom: 5px;
            position: relative;
            background: #ccc;
            border-radius: 3px;
            z-index: 1;
            transform-origin: 4px 0px;
            transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
                background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
                opacity 0.55s ease;

            &:first-child {
                transform-origin: 0% 0%;
            }

            &:nth-last-child(2) {
                transform-origin: 0% 100%;
            }
        }

        .header-nav {
            display: flex;
            box-shadow: $box-shadow-active;
            flex-direction: column;
            position: absolute;
            width: 150px;
            margin: -40px 0 0 -175px;
            padding: 40px 20px 20px;
            right: -5px;
            background: $mute-grey;
            -webkit-font-smoothing: antialiased;
            opacity: 0;
            visibility: hidden;
            transition: all .5s;
            z-index: -1;

            a {
                margin-left: 0;

                &:first-child {
                    padding-top: 0;
                }
            }
        }
    }
}

#mobile-menu {
    @media screen and (min-width: 746px) {
        input, span {
            display: none;
            visibility: hidden;
        }
    }

    @media screen and (max-width: 745px) {
        background: white;
        display: block;
        position: relative;
        top: .25em;
        left: 0;
        z-index: 1;
        -webkit-user-select: none;
        user-select: none;

        input {
            display: block;
            width: 40px;
            height: 32px;
            position: absolute;
            top: -7px;
            left: -5px;
            margin: 0;
            cursor: pointer;
            opacity: 0;
            z-index: 2;
            -webkit-touch-callout: none;

            &:checked ~ ul {
                transform: none;
            }

            &:checked ~ span {
                opacity: 1;
                transform: rotate(45deg) translate(-2px, -1px);
                background: #232323;
            }

            &:checked ~ span:nth-last-child(3) {
                opacity: 0;
                transform: rotate(0deg) scale(0.2, 0.2);
            }

            &:checked ~ span:nth-last-child(2) {
                transform: rotate(-45deg) translate(0, -1px);
            }
        }

        span {
            display: block;
            width: 33px;
            height: 4px;
            margin-bottom: 5px;
            position: relative;
            background: #ccc;
            border-radius: 3px;
            z-index: 1;
            transform-origin: 4px 0px;
            transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
                background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
                opacity 0.55s ease;

            &:first-child {
                transform-origin: 0% 0%;
            }

            &:nth-last-child(2) {
                transform-origin: 0% 100%;
            }
        }

        .sidebar-nav {
            box-shadow: $box-shadow-active;
            position: absolute;
            width: 220px;
            margin: -30px 0 0 -50px;
            padding: 50px;
            padding-top: 3em;
            background: white;
            -webkit-font-smoothing: antialiased;
            transform-origin: 0% 0%;
            transform: translate(-100%, 0);
            transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
        }
    }
}

.rainbow {
    animation: Gradient 10s ease infinite;
    background: linear-gradient(-45deg, rgba(223, 115, 83, .5), rgba(67, 177, 219, .5), rgba(58, 219, 98, .5), rgba(219, 197, 58, .5), rgba(187, 58, 219, .5));
    background-size: 400% 400%;
    padding: 2em;
    margin-bottom: 2em;
    border-radius: 10px;
}

@keyframes Gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.rainbow-dogs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1em;

    .dog {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin: $space;
        width: 20%;

        @media screen and (max-width: $home-breakpoint) {
            margin: 0 0 2em;
            width: 33.3%;
        }

        .female {
            color: $dark-secondary-color;
        }

        span {
            font-family: $heading-font;
            color: darken($brand-color, 35%);
            font-weight: 700;
            letter-spacing: 1px;

            &.soon {
                color: $white;
                font-weight: 500;
            }
        }
    }

    img.profile {
        border-radius: 100px;
        margin-bottom: $space;

        @media screen and (max-width: $home-sm-breakpoint) {
            width: 80%;
        }
    }
}

.rainbow-dog {
    display: flex;
    flex-direction: row;
}

.rainbow-icon {
    display: inline-block;

    &.small {
        height: 20px;
        width: 20px;
        margin-right: .5em;
    }

    &.large {
        height: 30px;
        width: 30px;
        margin-right: .25em;
    }
}
