// stylized button

button {
    background: $white;
    border: 1px solid $dark-brand-color;
    border-radius: 4px;
    box-shadow: $box-shadow;
    color: $dark-brand-color;
    cursor: pointer;
    display: inline-block;
    font-size: 1em;
    font-weight: 700;
    margin-top: $space;
    padding: $space $lg-space;

    &:hover {
        box-shadow: $box-shadow-active;
    }

    &.disabled {
        cursor: not-allowed;
        opacity: .5;

        &:hover {
            box-shadow: $box-shadow;
        }
    }
}

.call-to-action {
    align-items: center;
    background-color: $brand-color;
    display: flex;
    height: $height;
    justify-content: center;
    margin-bottom: 1em;
    position: relative;
    transition: background-color 500ms ease;
    z-index: 1;

    &:hover {
        background-color: $secondary-color;
        transition: background-color 500ms ease;
    }

    &.big {
        width: $width;
    }

    &.small {
        width: $sm-width;
    }

    span {
        color: $white;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;
        letter-spacing: 1px;
        line-height: 70px;
        font-size: 1.25em;
        font-weight: 700;
    }
}
